import React, { useEffect, useState } from 'react';
import EnhancedTable from 'src/components/enhanced-table/EnhancedTable';
import {
  EnhanceTableHeaderTypes,
  EnhancedTableColumnType,
  ITableMenuAction
} from 'src/components/enhanced-table';
import { useAppDispatch } from 'src/redux/hooks';
import { IConfirmation } from 'src/redux/confirmation/types';
import { OpenConfirmation } from 'src/redux/confirmation/confirmationReducer';
import { Helmet } from 'react-helmet-async';
import MainPageTitle from 'src/components/layout-components/main-layout/MainPageTitle';
import { useNavigate } from 'react-router';
import { DataService } from 'src/services/data-service';
import Loader from 'src/components/Loader';
import { ensurePlusPrefix } from 'src/helpers/utils';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

import {
  ITableFilterType,
  TableFilterTypes
} from 'src/components/enhanced-table/index-filter';
import AddResturant from './add-resturant';
import EditRestuarant from './edit-Resturant';
import EditRestuarantPass from './update-pass-Resturant';

interface IResturantResponseItem {
  name: string;
  active: boolean;
  mobileNumber: string;
  description: string;
  email: string;
  status: string;
  id: string;
  imageFile: string;
}

interface IResturantListing {
  name: string;
  active: boolean;
  mobileNumber: string;
  description: string;
  email: string;
  status: string;
  id: string;
  imageFile: string;
}

const ResturantListing: React.FC = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Record<string, IResturantListing>>();
  const [openAddResturant, setOpenAddResturant] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<IResturantListing>();
  const [openEditResturant, setOpenEditResturant] = useState<boolean>(false);

  const [openUpdatePassResturant, setOpenUpdatePassResturant] = useState<boolean>(false);


  const navigate = useNavigate();

  const activate = (id: string) => {
    const confimation: IConfirmation = {
      open: true,
      title: 'Confirmation',
      message: 'Do you want to activate this Restaurant?',
      onSubmit: async () => {
        const result = await DataService.post(
          '/api/Restaurant/activate?id=' + id,
          {}
        );
        if (result.ok) {
          initilize();
          toast.success('Success!');
        } else {
          toast.error('An error occured');
        }
      }
    };
    dispatch(OpenConfirmation(confimation));
  };

  const deactivate = (id: string) => {
    const confimation: IConfirmation = {
      open: true,
      title: 'Confirmation',
      message: 'Do you want to deactivate this Restaurant?',
      onSubmit: async () => {
        const result = await DataService.post(
          '/api/Restaurant/deactivate?id=' + id,
          {}
        );
        if (result.ok) {
          initilize();
          toast.success('Success!');
        } else {
          toast.error('An error occured');
        }
      }
    };

    dispatch(OpenConfirmation(confimation));
  };

  const edit = (id: string) => {
    setUpdateData(data[id]);
    setOpenEditResturant(true);
  };

  const updatePass = (id: string) => {
    setUpdateData(data[id]);
    setOpenUpdatePassResturant(true);
  };

  const download = async (id: string) => {
    if (data[id].imageFile) {
      window.open(data[id].imageFile, '_blank');
    }
  };

  const headers: EnhanceTableHeaderTypes[] = [
    {
      id: 'name',
      label: 'Name',
      type: EnhancedTableColumnType.Clickable,
      onClick: (id, row) => {
        navigate('/resturants/' + id);
      }
    },
    {
      id: 'mobileNumber',
      label: 'Mobile Number',
      type: EnhancedTableColumnType.PhoneNumber
    },
    { id: 'email', label: 'Email', type: EnhancedTableColumnType.TEXT },
    {
      id: 'description',
      label: 'Description',
      type: EnhancedTableColumnType.TEXT
    },
    {
      id: 'actions',
      label: 'Actions',
      type: EnhancedTableColumnType.Action,
      actions: [
        {
          onClick: (id) => {
            activate(id);
          },
          icon: <CheckIcon fontSize="small" />,
          label: 'Activate',
          hidden: (r) => r.status?.toLowerCase() === 'active'
        },
        {
          onClick: (id) => {
            deactivate(id);
          },
          icon: <CancelIcon fontSize="small" />,
          label: 'Deactivate',
          hidden: (r) => r.status?.toLowerCase() === 'inactive'
        },
        {
          onClick: (id) => {
            edit(id);
          },
          icon: <EditIcon fontSize="small" />,
          label: 'Edit',
          hidden: (r) => false
        },
        {
          onClick: (id) => {
            updatePass(id);
          },
          icon: <EditIcon fontSize="small" />,
          label: 'Edit Password',
          hidden: (r) => false
        },
        {
          onClick: (id) => {
            download(id);
          },
          icon: <CloudDownloadIcon fontSize="small" />,
          label: 'Download',
          hidden: (r) => false
        }
      ]
    }
  ];

  const tableFiler: ITableFilterType[] = [
    {
      type: TableFilterTypes.SELECT,
      name: 'status',
      title: 'Status',
      defaultValue: []
    }
  ];

  const initilize = async () => {
    setLoading(true);
    const response = await DataService.get('/api/Restaurant/all');

    if (response.ok) {
      const list: { data: IResturantResponseItem[] } = await response.json();
      console.log(list.data);
      const result: Record<string, IResturantListing> = {};

      list.data.forEach((c) => {
        result[c.id] = {
          name: c.name,
          imageFile: c.imageFile,
          id: c.id,
          email: c.email,
          description: c.description,
          mobileNumber: c.mobileNumber,
          status: c.active ? 'Active' : 'Inactive',
          active: c.active
        };
      });
      setData(result);
      setLoading(false);
    }
  };

  useEffect(() => {
    initilize();
  }, []);

  const actions: ITableMenuAction[] = [
    // {
    //     key: "addcontact",
    //     title: "Add Contact",
    //     disabled: (selected: string[]) => selected.length > 0,
    //     onClick: () => {
    //         setAddContact(true)
    //     },
    // },
    // {
    //     key: "editcontact",
    //     title: "Edit Contact",
    //     disabled: (selected: string[]) => selected.length === 0,
    //     onClick: (selected) => {
    //         const confirm: IConfirmation = {
    //             open: true,
    //             title: "Hi",
    //             message: "Are you sure?",
    //             onSubmit: () => { console.log("hi") }
    //         }
    //         dispatch(OpenConfirmation(confirm));
    //     }
    // }
  ];

  if (!data || loading) {
    return <Loader size={75} />;
  }

  return (
    <div>
      <Helmet>
        <title>Restaurants</title>
      </Helmet>
      <MainPageTitle
        title={'Restaurant Listing'}
        action={{
          title: 'Add',
          onClick: () => {
            if (!openAddResturant) {
              setOpenAddResturant(true);
            }
          }
        }}
      />

      {/* <MainPageTitle title="Customers" /> */}

      <EnhancedTable
        title="Restaurants"
        defaultOrder=""
        header={headers}
        data={data}
        filters={tableFiler}
        actions={actions}
      />

      {openAddResturant && (
        <AddResturant
          onSuccess={function (): void {
            toast.success('Success!');
            setOpenAddResturant(false);
            initilize();
          }}
          onClose={function (): void {
            setOpenAddResturant(false);
          }}
        />
      )}

      {openEditResturant && (
        <EditRestuarant
          onSuccess={function (): void {
            toast.success('Success!');
            setOpenEditResturant(false);
            initilize();
          }}
          onClose={function (): void {
            setOpenEditResturant(false);
          }}
          updateData={updateData}
        />
      )}

{openUpdatePassResturant && (
        <EditRestuarantPass
          onSuccess={function (): void {
            toast.success('Success!');
            setOpenUpdatePassResturant(false);
            initilize();
          }}
          onClose={function (): void {
            setOpenUpdatePassResturant(false);
          }}
          updateData={updateData}
        />
      )}
    </div>
  );
};

export default ResturantListing;
