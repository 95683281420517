import React, { useEffect, useState } from 'react';
// import MainLayout from "./layouts/main-layout/MainLayout";
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';

import { useAppSelector } from './redux/hooks';
import SidebarLayout from './layouts/SidebarLayout';
import CitiesListing from './pages/cities/cities-listing';
import CustomerPage from './pages/cities/CustomerPage';
import NeighborHoodListing from './pages/neighborhood/neighborhood-listing';
import UserListing from './pages/users/user-listing';
import DriverListing from './pages/drivers/driver-listing';
import ResturantListing from './pages/resturants/resturant-listing';
import RestuarantPage from './pages/resturants/resturantPage';
import DriverPage from './pages/drivers/driverPage';
import ResturantGroupListing from './pages/restaurant-groups/resturantGroupsPage';
import RestaurantsByGroup from './pages/restaurant-groups/restaurants-byGroup';
import PricingVersionsListing from './pages/pricing-versions/pricing-versions-listing';
import OrderEventsListing from './pages/order-logs/order-logs-listing';
import BlockedDriversListing from './pages/resturants/blockedDrivers';
import ContactListing from './pages/contacts/contact-list';
import ContactAddressListing from './pages/contacts/contact-address';
import OrderListing from './pages/orders/order-listing';

export const Protected: React.FC = () => {
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector((s) => s.user.isAuthenticated);
  const userRole = useAppSelector((s) => s.user.role);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login', { replace: true });
    }
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return (
      <Routes>
        <Route path="" element={<SidebarLayout />}>
          <Route path="/contacts" element={<ContactListing />} />
          <Route path="/contacts/:id" element={<ContactAddressListing />} />

          <Route path="/order-events-logs" element={<OrderEventsListing />} />

          <Route path="/orders" element={<OrderListing />} />

          {userRole?.toLowerCase() === 'admin' && (
            <>
              <Route path="/cities" element={<CitiesListing />} />

              <Route path="/customers/:id" element={<CustomerPage />} />
              <Route path="/customers/add" element={<CustomerPage />} />
              <Route path="/neighborhoods" element={<NeighborHoodListing />} />
              <Route path="/users" element={<UserListing />} />
              <Route path="/drivers" element={<DriverListing />} />
              <Route path="/drivers/:id" element={<DriverPage />} />
              <Route path="/resturants" element={<ResturantListing />} />
              <Route
                path="/resturant-groups"
                element={<ResturantGroupListing />}
              />
              <Route
                path="/resturant-pricing-versions"
                element={<PricingVersionsListing />}
              />

              <Route
                path="/resturants-byGroup/:id"
                element={<RestaurantsByGroup />}
              />
              <Route path="/resturants/:id" element={<RestuarantPage />} />
              <Route
                path="/resturants/blockedDrivers/:id"
                element={<BlockedDriversListing />}
              />
            </>
          )}
          <Route path="*" element={<div />} />
        </Route>
      </Routes>
    );
  }

  return <div />;
};
