import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import GenericDrawer from 'src/components/drawer/GenericDrawer';

interface IResturantEdit {
  name: string;
  active: boolean;
  mobileNumber: string;
  description: string;
  email: string;
  status: string;
  id: string;
}

interface IAddDiscountProps {
  onSuccess: () => void;
  onClose: () => void;
  updateData: IResturantEdit;
}

const EditRestuarantPass: React.FC<IAddDiscountProps> = ({
  onSuccess,
  onClose,
  updateData
}) => {
  const navigate = useNavigate();

  const formConfig: Record<string, DynamicFieldTypes> = {
    password: {
      type: DynamicField.TEXT,
      required: true,
      name: 'password',
      title: 'Password',
      value: '',
      inputType: 'password',
      disabled: false
    }
  };

  const submit = async (data) => {
    let data2 = {
      oldPassword: 'dummy',
      newPassword: data.password
    };

    const req = await DataService.post(
      '/api/Restaurant/force-update-password?id=' + updateData.id,
      data2
    );

    if (req.ok) {
      await onSuccess();
      return true;
    } else {
      toast.error('An Error occured!');
    }
    return false;
  };

  return (
    <>
      <GenericDrawer
        title={'Update Password - ' + updateData?.name}
        open={true}
        onClose={onClose}
      >
        <DynamicFormWidget
          title=""
          drawerMode
          fields={formConfig}
          onSubmit={async (e) => {
            return await submit(e);
          }}
        />
      </GenericDrawer>
    </>
  );
};

export default EditRestuarantPass;
