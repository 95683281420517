import { Modal } from '@mui/material';
import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FileType } from 'src/helpers/file-utils';
import GenericDrawer from 'src/components/drawer/GenericDrawer';
import { useEffect, useState } from 'react';
import { INeighborhoodDetails } from '.';
import { useParams } from 'react-router';

interface IAddUsersProps {
  onSuccess: () => void;
  onClose: () => void;
  updateData: any;
}

interface ICityListing {
  name: string;
  code: string;
  active: boolean;
  cityCode: string;
}

const EditContactAddress: React.FC<IAddUsersProps> = ({
  onSuccess,
  onClose,
  updateData
}) => {
  const [neighborhoods, setNeighborhoods] = useState<Record<string, ICityListing>>({});
  const [booted, setBooted] = useState<boolean>(false); // Track loading state
  
  // Initialize form fields configuration
  const formConfig: Record<string, DynamicFieldTypes> = {
    description: {
      type: DynamicField.TEXT,
      required: true,
      name: 'description',
      title: 'Description',
      value: updateData?.addressText || '',
      disabled: false
    },
    neighborhoodCode: {
      type: DynamicField.SELECT,
      required: true,
      name: 'neighborhoodCode',
      title: 'Neighborhood Code',
      value: updateData?.neighborhoodCode || '',
      disabled: false,
      items: neighborhoods && Object.fromEntries(Object.entries(neighborhoods).map(([key, val]) => [key, val.name]))
    }
  };

  // Submit form data
  const submit = async (data: any) => {
    console.log(data);
    const response = await DataService.post('api/Contact/update-address', { ...data, id: updateData.id }, undefined);

    if (response.ok) {
      onSuccess();
      return true;
    } else {
      const result: { message: string } = await response.json();
      toast.error(result.message || 'An error occurred!');
      return false;
    }
  };

  // Initialize neighborhoods and set them in state
  const initializeCities = async () => {
    try {
      const response = await DataService.get('api/Neighborhood/all');
      if (response.ok) {
        const res = await response.json();
        const neighborhoodsData: INeighborhoodDetails[] = res.data;
        
        // Map neighborhoods to an object by code
        const neighborhoodsMap: Record<string, ICityListing> = neighborhoodsData.reduce((acc, neighborhood) => {
          acc[neighborhood.code] = {
            name: neighborhood.name,
            code: neighborhood.code,
            cityCode: neighborhood.cityCode,
            active: neighborhood.active
          };
          return acc;
        }, {} as Record<string, ICityListing>);
        
        setNeighborhoods(neighborhoodsMap);
      } else {
        toast.error('Failed to fetch neighborhoods.');
      }
    } catch (error) {
      toast.error('An error occurred while fetching neighborhoods.');
    } finally {
      // Set booted to true after all data is loaded
      setBooted(true);
    }
  };

  // Single useEffect to handle initialization
  useEffect(() => {
    initializeCities();
  }, []); // Empty dependency array to run only once when component mounts

  if (!booted) {
    // Optionally show a loading spinner or message while booting
    return <div>Loading...</div>;
  }

  return (
    <GenericDrawer title={'Edit Address'} open={true} onClose={onClose}>
      <DynamicFormWidget
        title=""
        drawerMode
        fields={formConfig}
        onSubmit={async (e) => await submit(e)}
      />
    </GenericDrawer>
  );
};

export default EditContactAddress;
