import React, { useEffect, useState } from "react"
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import { DataService } from "./services/data-service";
import { IUserStore } from "./redux/user/types";
import { useDispatch } from "react-redux";
import { LoginUser } from "./redux/user/userReducer";
import { Protected } from "./Protected";
import { getUserToken } from "./helpers/user-token";
import Loader from "./components/Loader";
import BaseLayout from "./layouts/BaseLayout";
import LoginPage from "./pages/auth-pages/Login";

interface IUserDetailsResponse {
    success: boolean;
    message: string;
    data: {
        email: string;
        active: boolean;
        username: string;
        mobileNumber: string;
        role: string;
    }
}

export const Portal: React.FC = () => {

    const [loaded, setLoaded] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        DataService.get("api/user/details").then(async (res) => {
            if (res.ok) {
                const result: IUserDetailsResponse = await res.json();
                if(result.success)
                var newStore: IUserStore = {
                    token: getUserToken() || '',
                    active: true,
                    role: result.data.role,
                    isAuthenticated: true,
                    user: {
                        email: result.data.email,
                        active: true,
                        username: result.data.username,
                        mobileNumber: result.data.mobileNumber,
                    }
                };
                dispatch(LoginUser(newStore));
                setLoaded(true);
            } else {
                setLoaded(true);
            }
        }).catch(() => {
            setLoaded(true);
        });
    }, []);

    return loaded ? <Routes>
        <Route path="login" element={<BaseLayout children={<LoginPage />} />} />
        <Route path="*" element={<Protected />} />
    </Routes> : <Loader size={75} />
}