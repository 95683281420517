import React, { useEffect, useState } from 'react';
import EnhancedTable from 'src/components/enhanced-table/EnhancedTable';
import {
  EnhanceTableHeaderTypes,
  EnhancedTableColumnType
} from 'src/components/enhanced-table';
import { Helmet } from 'react-helmet-async';
import MainPageTitle from 'src/components/layout-components/main-layout/MainPageTitle';
import { DataService } from 'src/services/data-service';
import Loader from 'src/components/Loader';

import {
  ITableFilterType,
  TableFilterTypes
} from 'src/components/enhanced-table/index-filter';

interface IOrderEventResponseItem {
  event: string;
  timestamp: Date;
}

interface IOrderEvent {
  id: string;
  event: string;
  timestamp: Date;
}

const OrderEventsListing: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Record<string, IOrderEvent>>();

  const headers: EnhanceTableHeaderTypes[] = [
    {
      id: 'event',
      label: 'Event',
      type: EnhancedTableColumnType.TEXT
    },
    {
      id: 'timestamp',
      label: 'Timestamp',
      type: EnhancedTableColumnType.DATETIME
    }
  ];


  const initilize = async () => {
    setLoading(true);
    const response = await DataService.get(
      '/api/Order/get-event-logs'
    );

    if (response.ok) {
      const list: { data: IOrderEventResponseItem[] } = await response.json();
      const result: Record<string, IOrderEvent> = {};

      list.data.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()).forEach((c) => {
        const id = crypto.randomUUID();
        result[id] = {
          id: id,
          event: c.event,
          timestamp: c.timestamp
        };
      });
      setData(result);
      setLoading(false);
    }
  };

  useEffect(() => {
    initilize();
  }, []);

  if (!data || loading) {
    return <Loader size={75} />;
  }

  return (
    <div>
      <Helmet>
        <title>Order Events</title>
      </Helmet>
      <MainPageTitle
        title={'Order Events Logs'}
      />

      <EnhancedTable
        title="Order Events"
        header={headers}
        data={data}
      />
    </div>
  );
};

export default OrderEventsListing;
