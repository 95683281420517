import React, { useEffect, useState } from 'react';
import EnhancedTable from 'src/components/enhanced-table/EnhancedTable';
import {
  EnhanceTableHeaderTypes,
  EnhancedTableColumnType
} from 'src/components/enhanced-table';
import { Helmet } from 'react-helmet-async';
import MainPageTitle from 'src/components/layout-components/main-layout/MainPageTitle';
import { DataService } from 'src/services/data-service';
import Loader from 'src/components/Loader';

import {
  ITableFilterType,
  TableFilterTypes
} from 'src/components/enhanced-table/index-filter';
import { useNavigate } from 'react-router';

interface IOrderSummary {
  id: string;
  RestaurantName: string;
  DriverName: string;
  ContactName: string;
  RestaurantId: number;
  DriverId: number;
  ContactId: number;
  Status: string;
  CreatedDate: string;
  ReadyDate: string;
}

const OrderListing: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Record<string, IOrderSummary>>();

  const navigate = useNavigate();

  // Updated table headers to show all required columns
  const headers: EnhanceTableHeaderTypes[] = [
    {
      id: 'RestaurantName',
      label: 'Restaurant Name',
      type: EnhancedTableColumnType.Clickable,
      onClick: (id, row) => {
        navigate('/resturants/' + row.RestaurantId);
      }
    },
    {
      id: 'DriverName',
      label: 'Driver Name',
            type: EnhancedTableColumnType.Clickable,
      onClick: (id, row) => {
        navigate('/drivers/' + row.DriverId);
      }
    },
    {
      id: 'ContactName',
      label: 'Contact Name',
            type: EnhancedTableColumnType.Clickable,
      onClick: (id, row) => {
        navigate('/contacts/' + row.ContactId);
      }
    },
    // {
    //   id: 'RestaurantId',
    //   label: 'Restaurant ID',
    //   type: EnhancedTableColumnType.NUMBER
    // },
    // {
    //   id: 'DriverId',
    //   label: 'Driver ID',
    //   type: EnhancedTableColumnType.NUMBER
    // },
    // {
    //   id: 'ContactId',
    //   label: 'Contact ID',
    //   type: EnhancedTableColumnType.NUMBER
    // },
    {
      id: 'Status',
      label: 'Status',
      type: EnhancedTableColumnType.TEXT
    },
    {
      id: 'CreatedDate',
      label: 'Created Date',
      type: EnhancedTableColumnType.DATETIME
    },
    {
      id: 'ReadyDate',
      label: 'Ready Date',
      type: EnhancedTableColumnType.DATETIME
    }
  ];

  const tableFilter: ITableFilterType[] = [
    {
      type: TableFilterTypes.SELECT,
      name: 'status',
      title: 'Status',
      defaultValue: []
    }
  ];

  // Reusable conversion function
  const convertToOrderSummaries = (response: any): Omit<IOrderSummary, 'id'>[] => {
    if (!response || !response.data || !Array.isArray(response.data)) {
      return [];
    }
    return response.data.map((item: any) => ({
      RestaurantName: item.restaurant?.name || '',
      DriverName: item.driver?.name || '',
      ContactName: item.contact?.name || '',
      RestaurantId: item.restaurantId || 0,
      DriverId: item.driverId || 0,
      ContactId: item.contact?.id || 0,
      Status: item.status || '',
      CreatedDate: item.createdOn || '',
      ReadyDate: item.readyTime || ''
    }));
  };

  const initilize = async () => {
    setLoading(true);
    try {
      const response = await DataService.get('/api/Order/all');
      if (response.ok) {
        const json = await response.json();
        const convertedData = convertToOrderSummaries(json).sort((a, b) => new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime());

        // Transform array into a record keyed by a unique ID (e.g., using crypto.randomUUID)
        const recordData: Record<string, IOrderSummary> = {};
        convertedData.forEach((item) => {
          const id = crypto.randomUUID();
          recordData[id] = { id, ...item };
        });
        setData(recordData);
      }
    } catch (error) {
      console.error('Failed to fetch order data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initilize();
  }, []);

  if (loading || !data) {
    return <Loader size={75} />;
  }

  return (
    <div>
      <Helmet>
        <title>Order Events</title>
      </Helmet>

      <MainPageTitle title="Order Events Logs" />

      <EnhancedTable
        title="Order Events"
        header={headers}
        data={data}
        filters={tableFilter}
      />
    </div>
  );
};

export default OrderListing;
