import React, { useEffect, useState } from 'react';
import EnhancedTable from 'src/components/enhanced-table/EnhancedTable';
import {
  EnhanceTableHeaderTypes,
  EnhancedTableColumnType,
  ITableMenuAction
} from 'src/components/enhanced-table';
// import MainPageTitle from 'src/layouts/main-layout/MainPageTitle';
// import MainPageSection from 'src/layouts/main-layout/MainPageSection';
// import { IInformationWidgetField, InformationWidgetFieldTypes } from 'src/components/information-widget';
// import InformationWidget from 'src/components/information-widget/InformationWidget';
import { useAppDispatch } from 'src/redux/hooks';
import { IConfirmation } from 'src/redux/confirmation/types';
import { OpenConfirmation } from 'src/redux/confirmation/confirmationReducer';
import { ICustomDropdownOption } from 'src/components/CustomDropdown';
import { toast } from 'react-toastify';
import {
  IInformationWidgetField,
  InformationWidgetFieldTypes
} from 'src/components/widgets/information-widget';
import InformationWidget from 'src/components/widgets/information-widget/InformationWidget';
import { DataService } from 'src/services/data-service';
import { CustomerStatus, IOrder } from '.';
import { getStoreById } from 'src/utils/store-utils';
import Loader from 'src/components/Loader';
import { useNavigate, useParams } from 'react-router';
import { ensurePlusPrefix } from 'src/helpers/utils';
import { formatIntPhoneNumber } from 'src/helpers/formatting-utils';

// Config.ts

interface IRestuarantDetails {
  id: number;
  name: string;
  description: string;
  mobileNumber: string;
  email: string;
  active: string;
  defaultOrderTime: number;
}

interface IRestuarantDetailsApiResponse {
  id: number;
  name: string;
  description: string;
  mobileNumber: string;
  email: string;
  active: boolean;
  defaultOrderTime: number;
}

const fields: IInformationWidgetField[] = [
  {
    type: InformationWidgetFieldTypes.Text,
    name: 'name',
    title: 'Name',
    width: 'third'
  },
  {
    type: InformationWidgetFieldTypes.Text,
    name: 'description',
    title: 'Description',
    width: 'third'
  },
  {
    type: InformationWidgetFieldTypes.Text,
    name: 'mobileNumber',
    title: 'Mobile Number',
    width: 'third'
  },
  {
    type: InformationWidgetFieldTypes.Text,
    name: 'email',
    title: 'Email',
    width: 'third'
  },
  {
    type: InformationWidgetFieldTypes.Text,
    name: 'defaultOrderTime',
    title: 'Default Order Time',
    width: 'third'
  },
  {
    type: InformationWidgetFieldTypes.Text,
    name: 'active',
    title: 'Active',
    width: 'third'
  }
  // {
  //     type: InformationWidgetFieldTypes.Date,
  //     name: 'lastPurchaseDate',
  //     title: 'Last Purchase Date',
  //     width: 'third'
  // }
];

const headers: EnhanceTableHeaderTypes[] = [
  { id: 'createdOn', label: 'created On', type: EnhancedTableColumnType.DATE },
  { id: 'address', label: 'Address', type: EnhancedTableColumnType.TEXT },
  // {
  //   id: 'totalCharge',
  //   label: 'Total Charge',
  //   type: EnhancedTableColumnType.CURRENCY,
  //   currencySymbol: 'USD'
  // },
  {
    id: 'deliveryCharge',
    label: 'Delivery Charge',
    currencySymbol: '$',
    type: EnhancedTableColumnType.CURRENCY
  },
  {
    id: 'status',
    label: 'Status',
    type: EnhancedTableColumnType.COLORED_CHIP,
    chipColors: {
      Ready: { backgroundColor: 'purple', color: 'white' },
      Completed: { backgroundColor: 'green', color: 'white' },
      Cancelled: { backgroundColor: 'red', color: 'white' },
      Pending: { backgroundColor: 'yellow', color: 'white' }
    }
  }
];

const generateData = (orders: IOrder[]) => {
  const data: Record<string, Record<string, any>> = {};

  orders.forEach((t) => {
    //  const restaurant = getStoreById(t.storeId);
    data[t.id] = {
      restaurantId: t.restaurantId,
      restaurant: t.restaurant,
      driverId: t.driverId,
      driver: t.driver, // Random total amount
      customer: t.customer, // Random points gained
      contact: t.contact, // Random points redeemed
      totalCharge: t.totalCharge,
      deliveryCharge: t.deliveryCharge,
      address: t.address,
      status: t.status,
      paidToDriver: t.paidToDriver,
      paidToRestaurant: t.paidToRestaurant,
      createdOn: t.createdOn
    };
  });

  console.log(data, 'data');
  return data;
};

const RestuarantPage: React.FC = () => {
  const [data, setData] = useState<{
    loaded: boolean;
    restuarantDetails: Record<string, any>;
    orders: Record<string, any>;
  }>({
    loaded: false,
    restuarantDetails: {},
    orders: {}
  });
  const navigate = useNavigate();

  const { id } = useParams();

  const actions: ITableMenuAction[] = [
    // {
    //     key: "wow",
    //     title: "wow",
    //     disabled: (selected: string[]) => selected.length > 0,
    //     onClick: () => alert("clicked"),
    // },
    // {
    //     key: "wow1",
    //     title: "wow1",
    //     disabled: (selected: string[]) => selected.length === 0,
    //     onClick: (selected) => {
    //         const confirm: IConfirmation = {
    //             open: true,
    //             title: "Hi",
    //             message: "Are you sure?",
    //             onSubmit: () => console.log("hi")
    //         }
    //         dispatch(OpenConfirmation(confirm));
    //     }
    // }
  ];

  const infoActions: ICustomDropdownOption[] = [
    {
      title: 'Blocked Drivers',
      key: 'blockedDrivers',
      onClick: () => navigate('/resturants/blockedDrivers/' + id)
    }
  ];

  const initilize = async () => {
    const [restuarantDetails, Orders] = await Promise.all([
      getRestuarantDetails(),
      getOrders()
    ]);
    if (Orders && restuarantDetails) {
      setData({
        loaded: true,
        restuarantDetails: restuarantDetails,
        orders: Orders
      });
    }
  };

  const getOrders = async () => {
    const result: Record<string, Record<string, any>> = {};
    const response = await DataService.get(
      '/api/Order/get-restaurant?id=' + id
    );
    if (response.ok) {
      const result: { data: IOrder[] } = await response.json();
      return generateData(result.data);
    } else {
      toast.error('Something wrong happened!');
    }
    return result;
  };

  const getRestuarantDetails = async (): Promise<IRestuarantDetails> => {
    const response = await DataService.get('/api/Restaurant/get/' + id);
    if (response.ok) {
      const result: { data: IRestuarantDetailsApiResponse } =
        await response.json();
      // return generateData(result.result);
      return {
        id: result.data.id,
        name: result.data.name,
        description: result.data.description,
        mobileNumber: result.data.mobileNumber,
        email: result.data.email,
        active: result.data.active ? 'Active' : 'Inactive',
        defaultOrderTime: result.data.defaultOrderTime
      };
    } else {
      toast.error('Something wrong happened!');
    }
  };

  useEffect(() => {
    initilize();
  }, []);

  if (!data.loaded) {
    return <Loader size={75} />;
  }

  return (
    <div>
      {/* <MainPageTitle title={`Customer Info ${contactDetails.firstName} - ${contactDetails.lastName}`} /> */}
      <InformationWidget
        title="Restuarant Information"
        fields={fields}
        data={data.restuarantDetails}
        actions={infoActions}
      />
      {
        <EnhancedTable
          title="Orders"
          header={headers}
          data={data.orders}
          actions={actions}
        />
      }
    </div>
  );
};

export default RestuarantPage;
